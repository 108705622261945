import { graphql } from 'gatsby';
import * as React from 'react';

import { Dialog, Transition } from '@headlessui/react'

import Layout from './Layout';

import { Divider, Tooltip, IconButton } from '@mui/material';
import Add from '@mui/icons-material/Add';

import { useDispatch } from 'react-redux';

import { LinkTable } from '../../helpers/admin/MenuHelper';
import DataService from '../../services/dataService';
import { siteMetadata } from '../../../gatsby-config';


const Menus = ({ data, location, pageContext }) => {

    const dispatch = useDispatch();

    const [menus, setMenus] = React.useState([]);
    const [creatingMenu, setCreatingMenu] = React.useState(false);
    const [editingMenu, setEditingMenu] = React.useState(false);
    const [selectedMenu, setSelectedMenu] = React.useState({})
    const [links, setLinks] = React.useState([]);

    React.useEffect(() => {
        console.log(data)
        setMenus(data.allDatoCmsMenu.nodes);
    }, []);


    const handleUpdateMenu = (newMenu) => {



        DataService.updateRecord(newMenu.id, {
            ...newMenu
        }).then( updatedMenu => {
            let cMenus = menus;
            const newMenuIndex = menus.indexOf(cMenus.filter( menu => menu.name == newMenu.name)[0]);
            cMenus.splice(newMenuIndex);
            cMenus.push(updatedMenu);
            setMenus(cMenus);
        })
    }

    const handleClosePopup = () => {

        creatingMenu ? setCreatingMenu(false) : setEditingMenu(false);
    }

    const handleDeleteMenu = (menuId) => {

        const confirm = window.confirm("Are you sure you want to delete this menu?");

        if (confirm) {
            DataService.deleteRecord(menuId)
                .then( success => {
                    let cMenus = menus;
                    let oldMenuIndex = menus.indexOf(menus.filter( m => m.id == menuId)[0]);
                    cMenus.splice(oldMenuIndex, 1);
                    setMenus([...cMenus]);
                })
        }
    }

    const handleCreateMenu = (newMenu) => {

        DataService.create({
            item_type: { type: "item_type", id: siteMetadata.datoCmsModelIds.Menu.toString() },
            website: data.datoCmsWebsite.id.split('-')[1],
            ...newMenu
        }).then( createdMenu => {

            setMenus([...menus, createdMenu])
        } )
    }

    const handleSaveMenu = (newMenu) => {

        DataService.updateRecord(menu.id, {
            ...newMenu
        }).then( updatedMenu => console.log(updatedMenu))
    }

    return (
        <Layout>
            <div>
                <h1 className="text-2xl font-bold text-center" style={{ fontFamily: 'monospace', color: '#45425a' }}>Menus</h1>

                <Divider />

                <div className="m-5">
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        {menus.map((menu) => (
                            <div
                                key={menu.name}
                                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                onClick={() => {
                                    setEditingMenu(true);
                                    setSelectedMenu(menu);
                                }}
                            >
                            <div className="flex-1 min-w-0">
                                <a href="#" className="focus:outline-none">
                                <span className="absolute inset-0" aria-hidden="true" />
                                <p className="text-sm font-medium text-gray-900">{menu.name}</p>
                                <p className="text-sm text-gray-500 truncate">{JSON.parse(menu.links).length} links</p>
                                </a>
                            </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>

            {
                creatingMenu && (
                    <div className="fixed top-0 left-0 h-screen w-screen" style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}>
                        <div className='absolute w-4/5 h-2/3 bg-white top-1/2 left-1/2 rounded p-8' style={{ transform: 'translate(-50%, -50%)' }}>
                            <LinkTable menu={{name: '', links: []}} handleUpdateMenu={handleUpdateMenu} handleClosePopup={handleClosePopup} handleCreateMenu={handleCreateMenu} />
                        </div>
                    </div>
                )
            }

            {
                editingMenu && (
                    <div className="fixed top-0 left-0 h-screen w-screen" style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}>
                        <div className='absolute w-4/5 h-2/3 bg-white top-1/2 left-1/2 rounded p-8' style={{ transform: 'translate(-50%, -50%)' }}>
                            <LinkTable menu={selectedMenu} handleUpdateMenu={handleUpdateMenu} handleClosePopup={handleClosePopup} handleDeleteMenu={handleDeleteMenu} />
                        </div>
                    </div>
                )
            }

            <Tooltip 
                title={"Upload Image"} 
                placement='left-start'
                className="hi"
                style={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    backgroundColor: '#45425a',
                    width: 100,
                    height: 100,
                    WebkitBoxShadow: '1px 2px 14px 1px rgba(0,0,0,0.80)',
                    boxShadow: '1px 2px 14px 1px rgba(0,0,0,0.80)' 
                }}
            >
                <IconButton 
                    onClick={() => setCreatingMenu(true) }
                >
                    <Add style={{ textAlign: 'right', fontSize: 50, color: 'white' }} />
                </IconButton>
            </Tooltip>
        </Layout>
    )
}


export const query = graphql`
  query AdminMenusQuery($sitename: String) {
    allDatoCmsMenu(filter: {website: {name: {eq: $sitename}}}) {
        nodes {
          id
          name
          links
        }
      }
    datoCmsWebsite(name: { eq: $sitename }) {
        id
    }
  }
`;

export default Menus;