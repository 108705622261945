import * as React from 'react';

import { 
    TableContainer, 
    Table,
    TableCell, 
    TableRow, 
    TableBody, 
    TableHead, 
    Tooltip, 
    IconButton,
    Select,
    MenuItem,
    Button
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { DeleteForeverOutlined } from '@mui/icons-material';
import { Close } from '@mui/icons-material'; 

import { TextField } from '@mui/material';

import { get } from 'lodash';
import { Link } from 'gatsby';

const LinkTable = ({ menu, handleCreateMenu, handleUpdateMenu, handleDeleteMenu, linkLimit, menuName, handleClosePopup }) => {

    const [menuTitle, setMenuTitle] = React.useState(menu.name.length ? menu.name : '');
    const [links, setLinks] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [columns, setColumns] = React.useState([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'title', headerName: 'Title', width: 100 },
        { field: 'type', headerName: 'Type', width: 100 },
        { field: 'value', headerName: 'Value', width: 100 },
        { field: 'add', headerName: 'Add', wiidth: 100 }
    ]);
    const [subMenus, setSubMenus] = React.useState([]);

    const setSubmenus = React.useCallback((cSubMenus) => {
        setSubMenus(cSubMenus);
    }, []);

    React.useEffect(() => {

        setSubMenus([]);
        const tRows =  typeof menu.links == 'string' ? JSON.parse(menu.links) : menu.links;
        console.log(tRows);
        setLinks(tRows);
    }, [menu]);

    const addLink = (link) => {

        setLinks([...links, link]);
    }

    React.useEffect(() => { console.log(links); }, [links]);

    return (
        <div style={{ width: '100%' }} className="flex flex-col justify-between">

            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                <TextField
                    placeholder={'title'}
                    value={menuTitle}
                    title={'Menu Name'}
                    onChange={(e) => setMenuTitle(e.target.value)}
                    className="mb-4 w-2/5"
                />


                <div className="flex justify-around">
                    {typeof handleCreateMenu == 'undefined' && (
                        <Button 
                            variant='outlined'
                            className="m-2 mb-4"
                            title={'delete menu'}
                            color={'error'}
                            onClick={() => {
                                handleDeleteMenu(menu.id);
                                handleClosePopup();
                            }}
                        >
                            Delete Menu
                        </Button>
                    )}

                    <Button 
                        variant='outlined'
                        className="m-2 mb-4"
                        title={typeof handleCreateMenu !== 'undefined' ? 'Create Menu' : 'Update Menu'}
                        onClick={() => {
                            if (typeof handleCreateMenu !== 'undefined') {
                                handleCreateMenu({ id: menu.id, name: menuTitle, links: JSON.stringify(links) });
                                handleClosePopup();
                                return;
                            }

                            handleUpdateMenu({ id: menu.id, name: menuTitle, links: JSON.stringify(links) });
                            handleClosePopup();
                        }}
                    >
                        {typeof handleCreateMenu !== 'undefined' ? 'Create Menu' : 'Update Menu'}
                    </Button>
                </div>
            </div>

            <TableContainer style={{ borderRadius: 4, borderWidth: 1, borderColor: '#45425a', borderStyle: 'solid' }}>
                <Table>
                    <TableHead style={{ backgroundColor: '#45425a' }}>
                        <TableRow>
                            {
                                columns.map( column => {

                                    if (column.field == 'add') {
                                        return (
                                            <TableCell>
                                                <Tooltip 
                                                    title="Add"
                                                    placement='bottom'
                                                    className="hi"
                                                >
                                                <IconButton onClick={() => {

                                                    // let cTRows = links ? rows : [];
                                                    // cTRows.push({
                                                    //     name: '',
                                                    //     type: 'url',
                                                    //     url: '' 
                                                    // });
                                                    // setRows([...cTRows]);
                                                    addLink({
                                                        name: '',
                                                        type: 'url',
                                                        url: '' 
                                                    })
                                                }}>
                                                    <AddIcon 
                                                        style={{ color: 'white' }} />
                                                </IconButton>
                                                
                                                </Tooltip>
                                            </TableCell>
                                        )
                                    }

                                    return (
                                        <TableCell style={{ color: 'white', fontSize: 20, textAlign: 'center' }}>
                                            { column.headerName }
                                        </TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            links.map( (link, index) => (
                                <TableRow style={{ backgroundColor: (index % 2 != 0) ? 'white' : 'whitesmoke' }}>
                                    {/* {JSON.stringify(link)} */}
                                    <TableCell>{index}</TableCell>
                                    <TableCell>
                                        <TextField 
                                            placeholder='url'
                                            value={link.title}
                                            onChange={(e) => {
                                                
                                                let cRow = links[index];
                                                cRow.title = e.target.value;
                                                
                                                let cRows = links;
                                                cRows[index] = cRow;

                                                // setRows(cRows);
                                                setLinks([...cRows])
                                                // updateSettings(cRows, pointerString);
                                            }}
                                            style={{
                                                color: 'white'
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Select 
                                            value={link.type}
                                            style={{ width: '100%' }}
                                            onChange={(e) => {
                                                let cRow = links[index];
                                                cRow.type = e.target.value;

                                                let cRows = links;
                                                cRows[index] = cRow;

                                                setRows([...cRows]);
                                                setLinks([...cRows])
                                                // updateSettings(cRows, pointerString);
                                            }}
                                            name={link.title}
                                        >
                                            <MenuItem value={"url"}>url</MenuItem>
                                            <MenuItem value={"page"}>page</MenuItem>
                                            <MenuItem value={"subMenu"}>subMenu</MenuItem>
                                        </Select>
                                    </TableCell>
                                    {
                                        Object.keys(link).map( (rowKey, keyIndex) => {
                                            if (rowKey == 'url') {
                                                // console.log(row.type)
                                                switch (link.type) {
                                                    case 'url':
                                                        return (
                                                            <TableCell>
                                                                <TextField 
                                                                    placeholder='url'
                                                                    defaultValue={link.url}
                                                                    style={{ width: '100%' }}
                                                                    onChange={(e) => {

                                                                        let cRow = links[index];
                                                                        cRow.url = e.target.value;

                                                                        let cRows = links;
                                                                        cRows[index] = cRow;

                                                                        // setRows(cRows);
                                                                        // updateSettings(cRows, pointerString);
                                                                        setLinks([...cRows])
                                                                    }}
                                                                />
                                                            </TableCell>
                                                        );
                                                    case 'page':
                                                        return (
                                                            <TableCell>
                                                                <Select 
                                                                    id={pointerString.split('.')[pointerString.length - 1] + "table-link" + keyIndex}
                                                                    style={{ width: '100%' }}
                                                                    defaultValue={rows[index].url.toLowerCase()}
                                                                    onChange={(e) => {

                                                                        let cRow = links[index];
                                                                        cRow.url = e.target.value.split().map( l => l.toLowerCase() ).join('');

                                                                        let cRows = links;
                                                                        cRows[index] = cRow;

                                                                        setLinks([...cRows])
                                                                    }}    
                                                                >
                                                                    {
                                                                        pageNames.map( page => (
                                                                            <MenuItem value={`/${page.charAt(0).toLowerCase() + page.slice(1)}`}>{page}</MenuItem>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </TableCell>
                                                        );
                                                    case 'subMenu':
                                                        return (
                                                            <TableCell>
                                                                <Select id={pointerString.split('.')[pointerString.length - 1] + "table-link" + index}>
                                                                    {
                                                                        subMenus.map( menu => (
                                                                            <MenuItem value={menu.name}>{menu.name}</MenuItem>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </TableCell>
                                                        )
                                                }
                                            }
                                        })
                                    }
                                    <TableCell>
                                        <Tooltip 
                                            title="Remove" 
                                            placement='bottom'
                                        >
                                            <IconButton onClick={() => {

                                                // console.log(links[index])
                                                const newLinks = links.filter( (link, i) => i !== index);
                                                // console.log(newLinks)
                                                setLinks([...newLinks]);
                                            }}>
                                                <DeleteForeverOutlined />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <IconButton className="fixed -top-8 -right-8 text-white" onClick={() => {

                handleClosePopup()
            }}>
                <Close />
            </IconButton>

        </div>
    )
}

export {
    LinkTable
};
